import styled from '@emotion/styled'

const Container = styled.div`
  margin: 4rem 0;
`

const Message = styled.h2`
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 2rem;
`

const NotFoundPage = () => (
  <>
    <Container>
      <Message>We couldn&apos;t find that :\</Message>
      <h3>Please check the address</h3>
    </Container>
  </>
)

export default NotFoundPage
